import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
} from "vue-router";

import { useAuthStore } from "@/store/Auth";
import { useBaseStore } from "@/store/Base";
import { useConversationStore } from "@/store/Conversation";
import routes from "./routes";
import restoreSession from "@/helpers/sessionRestore";

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL || ""),
  routes,
});

/**
 * Check the permissions of a user and route
 *
 * @param {object} to The route to navigate to
 */
const isPermitted = (to: RouteLocationNormalized) => {
  if (to.meta.requiresNoAuth) {
    return true;
  }

  const authStore = useAuthStore();
  return authStore.isLoggedIn;
};

router.beforeEach(async (to, from, next) => {
  const conversationStore = useConversationStore();
  // We want to reset the isAnonymising if there is a route change
  if (conversationStore.isAnonymising) {
    conversationStore.setIsAnonymising(false);
  }

  if (!isPermitted(to)) {
    if (await restoreSession()) {
      return next();
    }
    const authStore = useAuthStore();
    authStore.setLoginRedirect(to);
    return next("/login");
  }

  const baseStore = useBaseStore();
  baseStore.setPreviousRoute(from);

  return next();
});

/**
 * This is a temporary fix to refresh the cache if vite loads dynamic modules,
 * which already have an old version of it stored in the cache.
 * See: https://github.com/vitejs/vite/issues/11804
 **/
router.onError((error: Error, to: RouteLocationNormalized) => {
  if (error.message.includes("Failed to fetch dynamically imported module")) {
    window.location.href = to.fullPath;
    window.location.reload();
  }
});

export default router;
