const HomeView = () => import("@/views/HomeView.vue");
const DossierView = () => import("@/views/DossierView.vue");
const LoginView = () => import("@/views/LoginView.vue");
const ProfileView = () => import("@/views/ProfileView.vue");
const PasswordReset = () => import("@/views/PasswordReset.vue");
const ForgotPassword = () => import("@/views/ForgotPassword.vue");
const CounselorList = () => import("@/views/CounselorList.vue");
const CounselingArchive = () => import("@/views/CounselingArchive.vue");
const AvailabilityView = () => import("@/views/AvailabilityView.vue");

import { useAuthStore } from "@/store/Auth";
import { destroyRefreshToken } from "@/services/auth";
import type { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/:tab(\\d+)?",
    name: "home",
    component: HomeView,
  },
  {
    path: "/dossier/:id",
    name: "dossier",
    component: DossierView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: "/profile/:id?",
    name: "profile",
    component: ProfileView,
    props: true,
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: PasswordReset,
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: "/counselors",
    name: "counselor-list",
    component: CounselorList,
  },
  {
    path: "/archive/:tab?",
    name: "counseling-archive",
    component: CounselingArchive,
  },
  {
    path: "/availability/:tab?",
    name: "availability",
    component: AvailabilityView,
  },
  {
    path: "/logout",
    name: "logout",
    component: {},
    beforeEnter: async (to: any, from: any, next: any) => {
      const authStore = useAuthStore();
      const refreshToken = authStore.refreshToken;
      next({ name: "login" });

      if (refreshToken) {
        await destroyRefreshToken(refreshToken);
      }
      authStore.removeAuth();
    },
  },
];

export default routes;
