import type ILanguage from "@/interfaces/ILanguage";
import { getGenders, getLanguages, getExpertGroups } from "../services/auth";
import type IGender from "@/interfaces/IGender";
import type ICategory from "@/interfaces/ICategory";
import { getCategories } from "@/services/counseling";
import type IExpertGroup from "@/interfaces/IExpertGroup";
// @ts-ignore TODO
// import { Route } from "vue-router";
import { defineStore } from "pinia";
import type { RouteLocationNormalized } from "vue-router";

interface State {
  languages: ILanguage[] | null;
  genders: IGender[] | null;
  categories: ICategory[] | null;
  expertGroups: IExpertGroup[] | null;
  previousRoute: RouteLocationNormalized | null;
  isSwitchingTenant: boolean;
}

export const useBaseStore = defineStore("base", {
  state: (): State => ({
    languages: null,
    genders: null,
    categories: null,
    expertGroups: null,
    previousRoute: null,
    // help to know that we're switching the tenant
    isSwitchingTenant: false,
  }),
  actions: {
    async loadLanguages() {
      if (!this.languages) {
        const languages = await getLanguages();
        this.languages = languages;
      }
    },

    async loadGenders() {
      if (!this.genders) {
        const genders = await getGenders();
        this.genders = genders;
      }
    },

    setIsSwitchingTenant(value: boolean) {
      this.isSwitchingTenant = value;
    },

    /**
     * Needed in case of a language switch
     */
    clearGenders() {
      this.genders = null;
    },

    /**
     * Needed in case of a language switch
     */
    clearLanguages() {
      this.languages = null;
    },

    async loadCategories() {
      if (!this.categories || this.categories.length == 0) {
        const categories = await getCategories();
        this.categories = categories;
      }
    },

    async loadExpertGroups() {
      if (!this.expertGroups) {
        const expertGroups = await getExpertGroups();
        this.expertGroups = expertGroups;
      }
    },

    setPreviousRoute(previousRoute: RouteLocationNormalized) {
      this.previousRoute = previousRoute;
    },

    setExpertGroups(expertGroups: IExpertGroup[] | null) {
      this.expertGroups = expertGroups;
    },

    setCategories(categories: ICategory[]) {
      this.categories = categories;
    },
  },
});
