import type IDialog from "@/interfaces/IDialog";
import { messagingApi } from "./api";
import type IDossier from "@/interfaces/IDossier";
import type ICategory from "@/interfaces/ICategory";

const sendMessage = async (
  text: string,
  dialogId: number,
  isDraft: boolean
) => {
  // send along the dialog uuid
  const data = {
    text,
    dialogId,
    isDraft,
  };
  const response = await messagingApi.post("counseling/message/", data);

  return response.data;
};

const getConversation = async (dialogId: number) => {
  const response = await messagingApi.get(
    `counseling/conversation/${dialogId}/`
  );

  return response.data;
};

const getDialogs = async (
  filter: string,
  showFinished: boolean,
  page: number
) => {
  const response = await messagingApi.get(
    `counseling/dialog/?${filter}&show_finished=${showFinished}&page=${page}`
  );
  return response.data;
};

const getDialogsFilter = async (filter: string, showFinished: boolean) => {
  const response = await messagingApi.options(
    `counseling/dialog/?${filter}&show_finished=${showFinished}`
  );
  return response.data;
};

const updateDraft = async (id: number, text: string) => {
  const data = {
    text,
  };
  const response = await messagingApi.put(`counseling/draft/${id}/`, data);

  return response.data;
};

const deleteDraft = async (id: number) => {
  const response = await messagingApi.delete(`counseling/draft/${id}/`);

  return response.data;
};

const getDossier = async (id: number) => {
  const response = await messagingApi.get(`counseling/dossier/${id}/`);

  return response.data;
};

const getDossierAnonymous = async (id: number): Promise<IDossier> => {
  const response = await messagingApi.get(
    `counseling/dossier/${id}/?anonymous`
  );

  return response.data;
};

const sendDraft = async (id: number) => {
  const response = await messagingApi.post(`counseling/draft/${id}/send/`);

  return response.data;
};

const getAllExperts = async () => {
  // TODO: Implement tenant
  const response = await messagingApi.get(`/account/expert/`);
  return response.data;
};

const setDossierExpert = async (dossierId: number, expertId: number | null) => {
  await messagingApi.patch(`/counseling/dossier/${dossierId}/`, {
    expert: expertId,
  });
};

const rejectDossier = async (dossierId: number) => {
  await messagingApi.patch(`/counseling/dossier/${dossierId}/`, {
    expert: null,
    isAssignmentRejected: true,
  });
};

const setDossierFinishedState = async (
  dossierId: number,
  dossierState: boolean
) => {
  await messagingApi.patch(`/counseling/dossier/${dossierId}/`, {
    isFinished: dossierState,
  });
};

const uploadDocument = async (formData: FormData) => {
  const response = await messagingApi.post(`counseling/attachment/`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
};

const deleteAttachment = async (attachmentId: number) => {
  const response = await messagingApi.delete(
    `counseling/attachment/${attachmentId}/`
  );
  return response.data;
};

const downloadAttachment = async (link: string) => {
  const response = await messagingApi.get(link, { responseType: "blob" });
  return response.data;
};

const deleteComment = async (id: number) => {
  const response = await messagingApi.delete(`counseling/comment/${id}/`);
  return response.data;
};

const saveComment = async (id: number, text: string) => {
  const response = await messagingApi.put(`counseling/comment/${id}/`, {
    text,
  });
  return response.data;
};

const addComment = async (messageId: number, text: string) => {
  const response = await messagingApi.post(`counseling/comment/`, {
    text,
    message: messageId,
  });
  return response.data;
};

const updateDialog = async (dialog: IDialog) => {
  const response = await messagingApi.put(
    `counseling/dialog/${dialog.id}/`,
    dialog
  );
  return response.data;
};

const patchDialog = async (dialog: any) => {
  const response = await messagingApi.patch(
    `counseling/dialog/${dialog.id}/`,
    dialog
  );

  return response.data;
};

const setDossierLanguage = async (dossierId: number, languageId: number) => {
  await messagingApi.patch(`/counseling/dossier/${dossierId}/`, {
    language: languageId,
  });
};

const getPublicDialogs = async (
  filter: string,
  showFinished: boolean,
  page: number
) => {
  const response = await messagingApi.get(
    `counseling/public-dossier/?${filter}&show_finished=${showFinished}&page=${page}`
  );
  return response.data;
};

const getPublicDialogsFilter = async (
  filter: string,
  showFinished: boolean
) => {
  const response = await messagingApi.options(
    `counseling/public-dossier/?${filter}&show_finished=${showFinished}`
  );
  return response.data;
};

const getCategories = async (): Promise<ICategory[]> => {
  const response = await messagingApi.get(`/counseling/category/`);
  return response.data;
};

export {
  sendMessage,
  sendDraft,
  getConversation,
  updateDraft,
  deleteDraft,
  getDialogs,
  getDossier,
  getAllExperts,
  setDossierExpert,
  setDossierFinishedState,
  uploadDocument,
  deleteAttachment,
  downloadAttachment,
  deleteComment,
  saveComment,
  addComment,
  getDossierAnonymous,
  updateDialog,
  setDossierLanguage,
  getPublicDialogs,
  getCategories,
  patchDialog,
  rejectDossier,
  getDialogsFilter,
  getPublicDialogsFilter,
};
