import LoadingSVG from "./assets/icons/loading.svg?raw";
import type { Directive, DirectiveBinding } from "vue";

const addLoading = (el: any) => {
  el.insertAdjacentHTML(
    "afterbegin",
    `<div class="loading-directive"><div><div>${LoadingSVG}</div></div></div>`
  );
};

const removeLoading = (el: any) => {
  el.classList.remove("loading-directive__container");
  el.querySelectorAll(".loading-directive").forEach((value: Element) => {
    value.remove();
  });
};

/**
 * Toggle the loader
 *
 * @param {*} el
 * @param {*} binding
 */
const toggleLoading = (el: any, binding: DirectiveBinding<any>) => {
  const value = binding.value;

  if (value) {
    addLoading(el);
  } else {
    removeLoading(el);
  }
};

export const vLoading: Directive<any, any> = {
  beforeMount(el, binding) {
    toggleLoading(el, binding);
  },
  updated(el, binding) {
    toggleLoading(el, binding);
  },
  unmounted(el) {
    removeLoading(el);
  },
};

export const vFocus: Directive<any, any> = {
  mounted: function (el, binding) {
    if (
      !Object.prototype.hasOwnProperty.call(binding, "value") ||
      binding.value
    ) {
      el.focus();
    }
  },
};

export const vBlur: Directive<any, any> = {
  mounted(el) {
    el.addEventListener("click", () => {
      el.blur();
    });
  },
};
