<template>
  <div :class="{ 'w-16': isIE }" @click.stop="">
    <nav
      :class="{
        'w-64 shadow-lg': isNavigationOpen,
        sticky: !isIE,
        fixed: isIE,
      }"
      class="sticky w-16 space-y-10 transition-all duration-100 ease-linear sidenavi gradient-bg"
    >
      <div class="flex flex-col h-full overflow-x-hidden overflow-y-auto">
        <router-link
          :to="{ name: 'home' }"
          class="flex justify-start mb-6 sm-height:mb-4"
          exact
          data-testid="nav-home"
        >
          <div class="svg-wrapper">
            <SvgIcon
              v-tooltip.right="{
                content: consulationsTooltip,
                ...popperOptions,
              }"
              name="comments-light"
              :aria-hidden="isNavigationOpen"
              :title="NavigationLabels.consultationsLinkLabel.value"
            />
          </div>

          <div
            v-show="isNavigationOpen"
            class="ml-2 overflow-hidden whitespace-nowrap"
          >
            {{ NavigationLabels.labels.value.home }}
          </div>
        </router-link>
        <router-link
          v-if="isNotSecretary"
          :to="{ name: 'counseling-archive' }"
          class="flex mb-6 sm-height:mb-4"
          data-testid="nav-archive"
        >
          <div class="svg-wrapper">
            <SvgIcon
              v-tooltip.right="{
                content: getTooltipContent('navigation.counselingArchive'),
                ...popperOptions,
              }"
              name="archive-light"
              :aria-hidden="isNavigationOpen"
              :title="getTooltipContent('navigation.counselingArchive')"
            />
          </div>

          <span v-show="isNavigationOpen" class="ml-2">
            {{ NavigationLabels.labels.value["counseling-archive"] }}
          </span>
        </router-link>
        <router-link
          :to="{ name: 'availability' }"
          class="flex mb-6 sm-height:mb-4"
          data-testid="nav-availability"
        >
          <div class="svg-wrapper">
            <SvgIcon
              v-tooltip.right="{
                content: getTooltipContent('navigation.availability'),
                ...popperOptions,
              }"
              name="clock-light"
              :aria-hidden="isNavigationOpen"
              :title="getTooltipContent('navigation.availability')"
            />
          </div>
          <span v-show="isNavigationOpen" class="ml-2">
            {{ $t("navigation.availability") }}
          </span>
        </router-link>
        <router-link
          :to="{ name: 'counselor-list' }"
          class="flex mb-6 sm-height:mb-4"
          data-testid="nav-counselors"
        >
          <div class="svg-wrapper">
            <SvgIcon
              v-tooltip.right="{
                content: getTooltipContent('navigation.counselorList'),
                ...popperOptions,
              }"
              name="users-light"
              :aria-hidden="isNavigationOpen"
              :title="getTooltipContent('navigation.counselorList')"
            />
          </div>
          <span v-show="isNavigationOpen" class="ml-2">
            {{ NavigationLabels.labels.value["counselor-list"] }}
          </span>
        </router-link>

        <a
          :href="mattermostLink"
          target="_blank"
          class="flex mb-6 text-black sm-height:mb-4"
          data-test-mattermost-link
        >
          <div class="svg-wrapper">
            <SvgIcon
              v-tooltip.right="{
                content: getTooltipContent('general.mattermost'),
                ...popperOptions,
              }"
              name="mattermost"
              :aria-hidden="isNavigationOpen"
              :title="getTooltipContent('general.mattermost')"
            />
          </div>

          <div v-show="isNavigationOpen" class="flex items-center ml-2">
            {{ NavigationLabels.labels.value.mattermost }}
            <SvgIcon
              aria-hidden="true"
              class="ml-2 svg--small"
              name="external-link-alt-regular"
            />
          </div>
        </a>

        <div
          v-if="hasMultipleTenants"
          class="flex items-end h-16 mt-auto text-gray-700 justify-stretch"
        >
          <button class="h-16 svg-wrapper tenant-button" @click="toggleSidebar">
            <SvgIcon
              v-tooltip.right="{
                content: getTooltipContent('navigation.tenantSwitch'),
                ...popperOptions,
              }"
              name="chart-network-light"
              class="flex-shrink-0 fill-current"
              :title="getTooltipContent('navigation.tenantSwitch')"
            />
          </button>
          <TenantSwitch v-show="isNavigationOpen" class="h-16 ml-2" />
        </div>
      </div>
      <button
        class="flex items-center outline-none"
        :title="t('navigation.open')"
        @click="toggleSidebar"
      >
        <div class="svg-wrapper">
          <SvgIcon
            v-if="isNavigationOpen"
            name="times-light"
            class="self-end"
            :title="t('navigation.open')"
          />
          <SvgIcon
            v-else
            name="bars-light"
            class="self-end"
            aria-hidden="true"
          />
        </div>

        <span v-show="isNavigationOpen" class="ml-2 capitalize">
          {{ $t("general.close") }}
        </span>
      </button>
    </nav>
    <div
      v-if="isAnonymising"
      class="absolute top-0 left-0 w-20 h-full bg-modal-backdrop"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, onDeactivated } from "vue";
import TenantSwitch from "@/components/TenantSwitch.vue";

import { useAuthStore } from "@/store/Auth";
import { useConversationStore } from "@/store/Conversation";
import { useNavigationLabels } from "./NavigationLabelMixin";
import { useI18n } from "vue-i18n";
import { useRuntimeEnv } from "@core/src/helpers/environment";

const runtimeEnv = useRuntimeEnv();

const { t } = useI18n();

const authStore = useAuthStore();

const conversationStore = useConversationStore();

const isNavigationOpen = ref(false);
const isIE = ref(false);

const NavigationLabels = useNavigationLabels();

// This one is needed to fix the tooltip position (it seems like the position is buggy if a parent container has overflow-auto)
const popperOptions = {
  preventOverflow: true,
  boundary: "offsetParent",
};

const hasMultipleTenants = computed(() => {
  if (!authStore.availableTenants) return false;
  return authStore.availableTenants.length > 1;
});

const isAnonymising = computed(() => {
  return conversationStore.isAnonymising;
});

const mattermostLink = computed(() => {
  return `${runtimeEnv.VITE_MATTERMOST_URL}/${authStore.activeTenant}`;
});

const consulationsTooltip = computed(() => {
  return isNavigationOpen.value
    ? false
    : NavigationLabels.consultationsLinkLabel;
});

const isNotSecretary = computed(() => {
  return !authStore.isSecretary;
});

onMounted(() => {
  // if internet explorer
  if ((window.document as any).documentMode) {
    isIE.value = true;
  }

  window.addEventListener("click", closeSidebar);
});

onDeactivated(() => {
  window.removeEventListener("click", closeSidebar);
});

function toggleSidebar(): void {
  isNavigationOpen.value = !isNavigationOpen.value;
}

function closeSidebar(): void {
  isNavigationOpen.value = false;
}

function getTooltipContent(i18npath: string): boolean | string {
  // False is used to hide the tooltip
  return isNavigationOpen.value ? false : t(i18npath).toString();
}
</script>

<style lang="postcss" scoped>
nav {
  @apply flex flex-col justify-between px-2 py-8 rounded-br-xl rounded-tr-xl top-0;
  height: calc(100vh - 140px);
  min-height: 374px;
  margin-top: 90px;
  margin-bottom: 50px;
}

nav button {
  outline: none;
}
nav svg {
  @apply w-6 h-6;
  @apply text-black;
}

nav .svg-wrapper {
  @apply p-2 w-10;
  @apply flex justify-center flex-shrink-0 cursor-pointer;
}

nav .tenant-button {
  @apply pt-6;
}

nav .svg--small {
  @apply w-3 h-3 p-0 mr-0;
}

nav svg.isOpen {
  @apply mr-0 w-6 h-6;
}

nav span {
  @apply leading-5;
  @apply text-left;
  @apply text-black;
  /* We need this fixed width to prevent line jumping on opening */
  min-width: 160px;
}

nav svg + span {
  opacity: 0;
  animation: introducelabel 0.1s ease-in forwards;
}

nav a,
nav button {
  @apply no-underline items-center;
}

.router-link-active {
  & .svg-wrapper {
    @apply bg-white rounded-full;
  }

  & span {
    @apply font-bold;
  }
}

@keyframes introducelabel {
  100% {
    opacity: 1;
  }
}
</style>
