<template>
  <header class="py-8 bg-white">
    <div class="flex items-center justify-between">
      <router-link class="text-lg font-semibold no-underline" to="/">
        <img src="@/assets/logo.png" />
      </router-link>

      <VideoConferenceButton v-if="isLoggedIn" />

      <ProfileButton v-if="isLoggedIn" />

      <div v-if="isLoggedIn">
        <button class="text-xl" @click="logout">
          {{ $t("general.logout") }}
        </button>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import VideoConferenceButton from "@/components/VideoConferenceButton.vue";
import ProfileButton from "@/components/ProfileButton.vue";
import { useRouter } from "vue-router";
import { computed } from "vue";
import { useAuthStore } from "@/store/Auth";

const router = useRouter();

const authStore = useAuthStore();

const isLoggedIn = computed(() => {
  return authStore.isLoggedIn;
});

function logout() {
  router.push({ name: "logout" }).catch(() => {});
}
</script>
