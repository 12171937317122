<template>
  <button
    v-blur
    class="flex items-center justify-between btn btn--primary btn--icon btn--icon-left"
    :disabled="isHere"
    @click="click"
  >
    <SvgIcon name="user-light" aria-hidden="true" />
    <div>{{ $t("general.profile") }}</div>
  </button>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const isHere = computed(() => route.name === "profile" && !route.params.id);

const click = () => {
  router.push("/profile");
};
</script>
