import type ITenant from "@/interfaces/ITenant";
import type { RouteLocationNormalized } from "vue-router";
import type IPermissionGroup from "@/interfaces/IPermissionGroup";
//import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

const PermissionGroups = {
  // Careful about changing anything here! Double-check that these IDs exist and are the same
  // across all systems (dev, test, prod)
  TENANT_ADMIN_GROUP: 2,
  COUNSELOR_GROUP: 3,
  MENTOR_GROUP: 4,
  SUPER_COUNSELOR_GROUP: 1,
  // TBD: If needed in the frontend
  // PUBLIC_DOSSIER_GROUP: "Public Dossier",
  // SECRETARY_GROUP: "Sekretariat",
  //COUNSELOR_GROUPS: [COUNSELOR_GROUP, MENTOR_GROUP, SUPER_COUNSELOR_GROUP]
};

import { defineStore } from "pinia";

type Tenant = {
  name: string;
  tag: string;
};

interface State {
  id: number | null;
  token: string | null;
  refreshToken: string | null;
  firstName: string | null;
  lastName: string | null;
  alias: string | null;
  activeTenant: string | null;
  availableTenants: Array<Tenant> | null | undefined;
  loginRedirect: RouteLocationNormalized | null;
  tenantData: ITenant | null;
  isAnsweringPublicDossiers: boolean;
  isSecretary: boolean;
  groups: Array<IPermissionGroup>;
}

export const useAuthStore = defineStore("auth", {
  state: (): State => ({
    id: null,
    token: null,
    refreshToken: null,
    firstName: null,
    lastName: null,
    alias: null,
    activeTenant: null,
    availableTenants: null,
    loginRedirect: null,
    tenantData: null,
    isAnsweringPublicDossiers: false,
    isSecretary: false,
    groups: [],
  }),
  getters: {
    isLoggedIn(state) {
      return state.token !== null;
    },

    tenantHasAdditionalFields(state) {
      return (state.tenantData?.additionalFields || []).length > 0;
    },

    tenantAdditionalFields(state) {
      if (this.tenantHasAdditionalFields) {
        return state.tenantData?.additionalFields;
      }
      return [];
    },
    isTenantAdmin(state) {
      return !!state.groups.find(
        (group) => group.id == PermissionGroups.TENANT_ADMIN_GROUP
      );
    },
    isCounselor(state) {
      return !!state.groups.find(
        (group) => group.id == PermissionGroups.COUNSELOR_GROUP
      );
    },
    isMentor(state) {
      return !!state.groups.find(
        (group) => group.id == PermissionGroups.MENTOR_GROUP
      );
    },
    isSuperCounselor(state) {
      return !!state.groups.find(
        (group) => group.id == PermissionGroups.SUPER_COUNSELOR_GROUP
      );
    },
  },
  actions: {
    setToken(token: string) {
      this.token = token;
    },

    setRefreshToken(token: string) {
      sessionStorage.setItem("refreshToken", token);
      this.refreshToken = token;
    },

    setUserName({
      firstName,
      lastName,
    }: {
      firstName: string;
      lastName: string;
    }) {
      this.firstName = firstName;
      this.lastName = lastName;
    },

    async restoreSession() {
      const refreshTokenString = sessionStorage.getItem("refreshToken");
      if (refreshTokenString) {
        this.setRefreshToken(refreshTokenString);
      }
    },

    setAlias(alias: string) {
      this.alias = alias;
    },

    setActiveTenant(activeTenant: string) {
      this.activeTenant = activeTenant;
    },

    setAvailableTenants(availableTenants: Array<Tenant>) {
      this.availableTenants = availableTenants;
    },

    setId(id: number) {
      this.id = id;
    },

    setLoginRedirect(loginRedirect: RouteLocationNormalized | null) {
      this.loginRedirect = loginRedirect;
    },

    setTenantData(tenantData: ITenant) {
      this.tenantData = tenantData;
    },

    setIsAnsweringPublicDossiers(isAnsweringPublicDossiers: boolean) {
      this.isAnsweringPublicDossiers = isAnsweringPublicDossiers;
    },

    setIsSecretary(isSecretary: boolean) {
      this.isSecretary = isSecretary;
    },

    setGroups(groups: Array<IPermissionGroup>) {
      this.groups = groups;
    },

    removeAuth() {
      this.token = null;
      this.refreshToken = null;
      this.firstName = null;
      this.lastName = null;
      this.activeTenant = null;
      this.availableTenants = null;
      this.alias = null;
      this.groups = [];

      sessionStorage.clear();
    },
  },
});
