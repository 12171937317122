<template>
  <div class="flex flex-col w-full">
    <div class="text-xs">{{ $t("navigation.tenantSwitch") }}</div>

    <Multiselect
      v-model="activeTenant"
      class="w-full"
      :options="availableTenants"
      track-by="tag"
      label="name"
      :show-labels="false"
      :allow-empty="false"
    >
    </Multiselect>
  </div>
</template>

<script lang="ts" setup>
import restoreSession from "@/helpers/sessionRestore";
import { useAuthStore } from "@/store/Auth";
import { notify } from "@kyvg/vue3-notification";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useBaseStore } from "@/store/Base";

const authStore = useAuthStore();
const baseStore = useBaseStore();

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const availableTenants = computed(() => {
  return authStore.availableTenants;
});

const activeTenant = computed({
  get() {
    if (!availableTenants.value) return;

    return availableTenants.value.filter(
      (tenant) => tenant.tag == authStore.activeTenant
    );
  },
  async set(value: any) {
    if (!value) return;

    baseStore.setIsSwitchingTenant(true);

    // If we're on a dossier, push to home. It makes no sense to stay
    // on the dossier after a tenant switch
    if (route.name == "dossier") {
      // we can safely push to home because this TenantSwitch component
      // persists even if the route is changed
      // so the subsequent code is still being executed
      router.push({ name: "home" });
    }

    authStore.setActiveTenant(value.tag);

    if (!authStore.availableTenants) return;

    const tenantName = authStore.availableTenants.filter(
      (tenant) => tenant.tag == value.tag
    )[0].name;

    await restoreSession();
    baseStore.setIsSwitchingTenant(false);

    notify({
      type: "sucess",
      text: t("general.tenantSwitched", {
        tenant: tenantName,
      }).toString(),
    });
  },
});
</script>
