<template>
  <VDropdown @show="isShown = true" @hide="isShown = false">
    <button class="flex items-center ml-2 font-sans text-xl">
      <SvgIcon name="video-light" class="w-6 h-6" aria-hidden="true" />
      <div class="ml-2">{{ $t("general.videoConference") }}</div>
    </button>

    <template #popper>
      <div class="py-2">
        <!--
        The v-if part is a little trick to force the component to be
        re-rendered so each time the button is pressed, the URL will be
        genereated newly
      -->
        <VideoConferenceGenerator v-if="isShown" />
      </div>
    </template>
  </VDropdown>
</template>

<script lang="ts">
import VideoConferenceGenerator from "@/components/VideoConferenceGenerator.vue";

export default {
  components: {
    VideoConferenceGenerator,
  },
  data() {
    return {
      isShown: false,
    };
  },
};
</script>
