import { useAuthStore } from "@/store/Auth";
import { useBaseStore } from "@/store/Base";

import { refreshToken } from "@/services/auth";
import { setLocale } from "@/i18n";

const restoreSession = async () => {
  const authStore = useAuthStore();
  const baseStore = useBaseStore();
  await authStore.restoreSession();

  if (authStore.refreshToken) {
    try {
      const response = await refreshToken(authStore.refreshToken);
      authStore.setId(response.id);
      authStore.setToken(response.access);
      authStore.setRefreshToken(response.refresh);
      authStore.setUserName({
        firstName: response.firstName,
        lastName: response.lastName,
      });
      authStore.setGroups(response.groups);
      authStore.setAlias(response.aliasName);
      authStore.setActiveTenant(response.activeTenant);
      authStore.setAvailableTenants(response.availableTenants);
      baseStore.setExpertGroups(null);
      authStore.setIsAnsweringPublicDossiers(
        response.isAnsweringPublicDossiers
      );
      authStore.setIsSecretary(response.isSecretary);

      setLocale(response.language || "de");

      return true;
    } catch {
      authStore.removeAuth();
      return false;
    }
  }
};

export default restoreSession;
