import type IDialog from "@/interfaces/IDialog";

import { defineStore } from "pinia";

interface State {
  dialogs: IDialog[];
  dossierId: number;
  dossierMode: "comments" | "info";
  isAnonymising: boolean;
  scrollToDialogId: number;
  isUserDeleted: boolean;
}

export const useConversationStore = defineStore("conversation", {
  state: (): State => ({
    dialogs: [],
    dossierId: 0,
    dossierMode: "info",
    isAnonymising: false,
    scrollToDialogId: 0,
    isUserDeleted: false,
  }),
  getters: {
    dialogId(state) {
      return (
        state.dialogs.filter((dialog) => dialog.finishedTime === null)?.[0]
          ?.id || 0
      );
    },
  },
  actions: {
    setDialogs(dialogs: IDialog[]) {
      this.dialogs = dialogs;
    },

    setDossierId(id: number) {
      this.dossierId = id;
    },

    showComments() {
      this.dossierMode = "comments";
    },

    showInfo() {
      this.dossierMode = "info";
    },

    setIsAnonymising(isAnonymising: boolean) {
      this.isAnonymising = isAnonymising;
    },

    reset() {
      this.$reset();
    },

    setTextAnonymousFromMessage({
      text,
      messageId,
    }: {
      text: string;
      messageId: number;
    }) {
      const dialogs = this.dialogs.map((dialog) => {
        dialog.messages = dialog.messages.map((message) => {
          if (message.id === messageId) {
            message.textAnonymous = text;
          }
          return message;
        });
        return dialog;
      });
      this.setDialogs(dialogs);
    },

    setTopicAnonymousFromDialog({
      topic,
      dialogId,
    }: {
      topic: string;
      dialogId: number;
    }) {
      const dialogs = this.dialogs.map((dialog) => {
        if (dialog.id === dialogId) {
          dialog.topicAnonymous = topic;
        }
        return dialog;
      });
      this.setDialogs(dialogs);
    },

    setScrollToDialogId(scrollToDialogId: number) {
      this.scrollToDialogId = scrollToDialogId;
    },

    setIsUserDeleted(isUserDeleted: boolean) {
      this.isUserDeleted = isUserDeleted;
    },
  },
});
