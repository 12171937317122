import type IAutoResponse from "@/interfaces/IAutoResponse";
import type ICounselor from "@/interfaces/ICounselor";
import type IDefaultAvailability from "@/interfaces/IDefaultAvailability";
import type IProfile from "@/interfaces/IProfile";
import { userApi } from "./api";

/**
 * Perform a login
 *
 * @return User Info
 */
const login = async (
  email: string,
  password: string,
  twoFactorToken: string
) => {
  const response = await userApi.post("account/token/", {
    email: email,
    password: password,
    token: twoFactorToken ? twoFactorToken : null,
  });

  return response;
};

/**
 * Perform a refresh
 *
 * @return User Info
 */
const refreshToken = async (token: string) => {
  const response = await userApi.post("account/token/refresh/", {
    refresh: token,
  });

  const result = response.data;

  return result;
};

/**
 * Destroy the refresh token on logout
 *
 * @return empty
 */
const destroyRefreshToken = async (token: string) => {
  try {
    await userApi.post("account/token/destroy/", {
      refresh: token,
    });
    return;
  } catch (error) {
    return;
  }
};

const updateClient = async (
  id: number,
  gender: number,
  additionalFieldAnswers: any
) => {
  const response = await userApi.put(`account/client/${id}/`, {
    gender,
    additionalFieldAnswers,
  });

  return response.data;
};

const getLanguages = async () => {
  const response = await userApi.get(`account/language/`);

  return response.data;
};

const getMentors = async () => {
  const response = await userApi.get(`account/mentor/`);

  return response.data;
};

const getGenders = async () => {
  const response = await userApi.get(`account/gender/`);

  return response.data;
};

const getProfile = async (id: number): Promise<IProfile> => {
  const response = await userApi.get(`account/profile/${id}/`);

  return response.data;
};

const getBlocks = async () => {
  const response = await userApi.get(`availability/block/`);

  return response.data;
};

const orderAvailabilities = (availabilityList: IDefaultAvailability[]) => {
  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const defaultAvailabilities: any[] = [];

  // The availabilities are generated in the correct order in django.
  // But if someone deletes entries from the db directly, it could change.
  // So to be safe, we order them after weekdays here again.
  for (let i = 0; i < weekdays.length; i++) {
    defaultAvailabilities[i] = availabilityList.find(
      (obj: any) => obj.weekday === weekdays[i]
    );
  }

  return defaultAvailabilities;
};

const getDefaultAvailability = async () => {
  const response = await userApi.get(`availability/default-availability/`);

  return orderAvailabilities(response.data);
};

const getTeamAvailability = async (
  expertGroup: number | null,
  isAvailabilityFilter: boolean = false
) => {
  let filter = expertGroup ? `?expert_group=${expertGroup}` : "";
  if (isAvailabilityFilter) {
    filter += filter ? "&available=true" : "?available=true";
  }
  const response = await userApi.get(
    `availability/default-availability-all/${filter}`
  );

  return response.data.map((entry: { availabilities: any[] }) => {
    entry["availabilities"] = orderAvailabilities(entry["availabilities"]);
    return entry;
  });
};

const saveProfile = async (id: number, profile: IProfile) => {
  const response = await userApi.put(`account/profile/${id}/`, profile);

  return response.data;
};

const saveAvailability = async (id: number, availability: any) => {
  const response = await userApi.patch(
    `availability/default-availability/${id}/`,
    availability
  );

  return response.data;
};

const activate2FA = async (code: string) => {
  const response = await userApi.post(`account/activate-2fa/`, { code: code });

  return response.data;
};

const deactivate2FA = async () => {
  const response = await userApi.post(`account/deactivate-2fa/`);

  return response.data;
};

const getTwoFactorUrl = async () => {
  const response = await userApi.get(`account/2fa-url/`);

  return response.data;
};

const checkResetToken = async (token: string, email: string) => {
  const response = await userApi.post(`account/reset-password/check-token/`, {
    token,
    email,
  });

  return response.data;
};

const resetPassword = async (
  password: string,
  token: string,
  email: string
) => {
  try {
    const response = await userApi.post(`account/reset-password/reset/`, {
      password,
      token,
      email,
    });

    return response.data;
  } catch (error: any) {
    if (error.response.status == 400) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};

const forgotPassword = async (email: string) => {
  const response = await userApi.post(`account/reset-password/email/`, {
    email,
  });

  return response.data;
};

const changePassword = async (
  userId: number,
  oldPassword: string,
  newPassword: string
) => {
  const response = await userApi.put(`account/change-password/${userId}/`, {
    oldPassword,
    newPassword,
  });

  return response.data;
};

const getAutoResponse = async (userId: number): Promise<IAutoResponse> => {
  const response = await userApi.get(`account/auto-response/${userId}/`);

  return response.data;
};

const saveAutoResponse = async (
  userId: number,
  data: IAutoResponse
): Promise<IAutoResponse> => {
  const response = await userApi.put(`account/auto-response/${userId}/`, data);

  return response.data;
};

const getCounselors = async (filter: string): Promise<ICounselor[]> => {
  const response = await userApi.get(`account/counselors/?${filter}`);

  return response.data;
};

const getExpertGroups = async () => {
  const response = await userApi.get(`account/expert-group/`);

  return response.data;
};

export {
  login,
  refreshToken,
  destroyRefreshToken,
  updateClient,
  getLanguages,
  getProfile,
  getBlocks,
  getDefaultAvailability,
  saveProfile,
  saveAvailability,
  getGenders,
  getMentors,
  activate2FA,
  deactivate2FA,
  getTwoFactorUrl,
  checkResetToken,
  resetPassword,
  forgotPassword,
  changePassword,
  getAutoResponse,
  saveAutoResponse,
  getCounselors,
  getTeamAvailability,
  getExpertGroups,
};
