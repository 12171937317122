<template>
  <div class="flex flex-col">
    <div class="font-bold">
      {{ $t("videoConf.link") }}
    </div>

    <copy-input :value="url" class="mt-3" readonly />

    <button
      class="flex flex-row items-center justify-between mt-3 btn btn--primary class"
      @click="open"
    >
      <div>{{ $t("videoConf.open") }}</div>
      <div class="ml-3 text-white">
        <SvgIcon
          name="external-link"
          class="w-5 h-5 text-white fill-current"
          aria-hidden="true"
        />
      </div>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { v4 as gen_uuid } from "uuid";
import CopyInput from "@/components/CopyInput.vue";
import { useRuntimeEnv } from "@core/src/helpers/environment";

import { computed } from "vue";

const runtimeEnv = useRuntimeEnv();

const uuid = computed(() => {
  return gen_uuid();
});

const url = computed(() => {
  return `${runtimeEnv.VITE_JITSI_URL}/${uuid.value}`;
});

const open = () => {
  window.open(url.value, "_blank");
};
</script>
