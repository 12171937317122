<template>
  <div id="app" class="flex flex-col app-master">
    <div class="flex">
      <!-- force a re-rendering when the tenant changes -->
      <SafezoneMenu
        v-if="isLoggedIn"
        :key="activeTenant"
        class="fixed z-40 app-menu"
      />

      <div class="flex flex-col flex-1 app-content">
        <SafezoneHeader :key="activeTenant" class="px-16" />

        <div class="w-full px-16">
          <main class="pb-10 bg-white">
            <router-view v-if="!isSwitchingTenant" />
          </main>
        </div>
      </div>
    </div>

    <Notification />
  </div>
</template>

<script lang="ts" setup>
import { computed, watch } from "vue";
import Notification from "@core/src/components/Notification.vue";
import { useAuthStore } from "@/store/Auth";

import SafezoneMenu from "@/components/SafezoneMenu.vue";
import SafezoneHeader from "@/components/SafezoneHeader.vue";
import { useI18n } from "vue-i18n";
import { useBaseStore } from "./store/Base";

const { t, locale } = useI18n();

const authStore = useAuthStore();
const baseStore = useBaseStore();

const isLoggedIn = computed(() => {
  return authStore.isLoggedIn;
});

const activeTenant = computed(() => {
  return authStore.activeTenant || "activeTenant";
});

/**
 * If we're currently switching the tenant
 *
 * The problem is, that we set the active tenant and then
 * reload the session with this active tenant.
 * In between this, the router-view will be rendered with the
 * already-new tenant, resulting in requests with the new tenant, before the
 * session was reloaded.
 * Those new request might override the tenant again (i.e. with dossier),
 * thus resulting in the tenant not being switched...
 */
const isSwitchingTenant = computed(() => {
  return baseStore.isSwitchingTenant;
});

watch(
  () => locale.value,
  () => {
    document.title = `SafeZone - ${t("global.title")}`;
  },
  { immediate: true }
);
</script>

<style lang="postcss" scoped>
.app-content {
  width: calc(100% - 40px);
  padding-left: 66px;
}
</style>
