import axios, { type InternalAxiosRequestConfig, type AxiosInstance } from "axios";
import { useAuthStore } from "@/store/Auth";
import router from "../router";
import { refreshToken } from "./auth";
import i18n from "@/i18n";

let userApi: AxiosInstance;
let messagingApi: AxiosInstance;

const loginInterceptor = (config: InternalAxiosRequestConfig) => {
  const authStore = useAuthStore();
  if (authStore.isLoggedIn) {
    const token = authStore.token;
    // @ts-ignore TODO
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  // @ts-ignore TODO
  config.headers["Accept-Language"] = i18n.global.locale.value;

  return config;
};

const authInterceptor = async (error: any) => {
  const authStore = useAuthStore();
  // Return any error which is not due to authentication back to the calling service
  if (error.response.status !== 401) {
    return Promise.reject(error);
  }

  //Logout user if token refresh didn't work or user is disabled
  if (error.config.url.includes("token/refresh")) {
    // will error
    await authStore.removeAuth();
    router.push({ name: "login" });
    return Promise.reject(error);
  }

  if (!authStore.refreshToken) {
    return Promise.reject(error);
  }

  // Try request again with new token
  try {
    const token = await refreshToken(authStore.refreshToken);
    // set token in store
    authStore.setToken(token.access);
    authStore.setRefreshToken(token.refresh);

    // New request with new token
    const config = error.config;
    config.headers["Authorization"] = `Bearer ${token.access}`;
    return axios.request(config);
  } catch (error) {
    return Promise.reject(error);
  }
};

const tenantInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const authStore = useAuthStore();
  if (config.method == "post" || config.method === "put") {
    if (!config.data) {
      config.data = {
        tenant: authStore.activeTenant,
      };
    } else {
      config.data["tenant"] = authStore.activeTenant;
    }
  } else {
    if (!config.params) {
      config.params = {
        tenant: authStore.activeTenant,
      };
    } else {
      config.params["tenant"] = authStore.activeTenant;
    }
  }

  return config;
};

export function initializeApi(config: any) {
  userApi = axios.create({
    baseURL: config.VITE_API_URL,
  });

  messagingApi = axios.create({
    baseURL: config.VITE_API_URL,
  });

  const passThrough = (response: any) => response;

  messagingApi.interceptors.response.use(passThrough, authInterceptor);
  messagingApi.interceptors.request.use(loginInterceptor);
  messagingApi.interceptors.request.use(tenantInterceptor);

  userApi.interceptors.request.use(loginInterceptor);
  userApi.interceptors.request.use(tenantInterceptor);
  userApi.interceptors.response.use(passThrough, authInterceptor);
}

export { userApi, messagingApi };
