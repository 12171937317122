<template>
  <VueMultiselect v-bind="$attrs" :placeholder="t('general.pleaseChoose')" />
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import VueMultiselect from "vue-multiselect";

const { t } = useI18n();
</script>
